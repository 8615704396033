import React from "react";
import styled from "styled-components";
import tcs from "../../static/legal/Cloud House - Ranger Terms and Conditions of Use.pdf";
import pp from "../../static/legal/Cloud House - Ranger Privacy Policy.pdf";
import mpl from "../../static/images/microsoft partner logo-1.png";

import { Container } from "../global";
import { Link } from "gatsby";

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Legal</span>
        <ul>
          <li>
            <a href={tcs}>Terms and Conditions</a>
          </li>
          <li>
            <a href={pp}>Privacy Policy</a>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Resources</span>
        <ul>
          <li>
            <Link to="/howto/">Support Docs</Link>
          </li>
          <li>
            <Link to="/careers/">Careers</Link>
          </li>
          <li>
            <Link to="/content/">Articles and Content</Link>
          </li>
          {/* <li>
            <Link to="/about/">About</Link>
          </li> */}
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <img
        src={mpl}
        alt="Microsoft Partner Ranger"
        href="https://appsource.microsoft.com/en-us/product/office/WA200003237?tab=Overview"
        width="35%"
      />
    </BrandContainer>
    <BrandContainer>
      <Logo>RANGER</Logo>
    </BrandContainer>
    <CopyrightContainer>
      <Copyright>©2021 CloudHouse LLC</Copyright>
    </CopyrightContainer>
  </FooterWrapper>
);

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.background.white};
  margin: 80px 0 0;
  padding: 0 0 80px;
`;

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.logo.bold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`;

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`;

const CopyrightContainer = styled(Container)`
  position: relative;
  padding-top: 12px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.light};
  ${(props) => props.theme.font_size.xxxsmall};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  text-decoration: none;
  outline: 0px;
`;

const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`;

export default Footer;
